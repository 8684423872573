import React from 'react'
import * as HIcons from '@heroicons/react/24/outline'

interface Props {
  icon: any
  className?: string
}

const Bars3Icon: React.FC<Props> = ({ icon, className }) => {
  const { ...icons } = HIcons
  const TheIcon = icons[icon]

  return <TheIcon className={className} aria-hidden="true" />
}

export default Bars3Icon
