import React, { useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  ROUTES,
  MIN_WIDTH,
  BURGER_MIN_WIDTH,
  CSS_MODE,
  STANDALONE_S3,
} from '../../constants'
import { AuthenticationContext } from '../../helpers/Authentication'
import { useUser, useWindowWidth } from '../../helpers'
import { authService } from '../../services'
import { Disclosure } from '@headlessui/react'
import HeaderMenu from './HeaderMenu'
import HeaderLinks from './HeaderLinks'
import BurgerMenu from './BurgerMenu'

export interface HeaderNavigation {
  id?: string
  name: string
  href?: string
  icon?: any
  visible: boolean
}

export const navigation: HeaderNavigation[] = [
  {
    id: 'reporting',
    name: 'Reporting',
    href: ROUTES.REPORTS,
    icon: 'PresentationChartBarIcon',
    visible: true,
  },
  {
    id: 'customers',
    name: 'Customers',
    href: ROUTES.CUSTOMER_MANAGEMENT,
    icon: 'UsersIcon',
    visible: true,
  },
  {
    id: 'configuration',
    name: 'Configuration',
    href: ROUTES.CONFIGURATION,
    icon: 'PaintBrushIcon',
    visible: true,
  },
  {
    id: 'importExport',
    name: 'Import/Export',
    href: ROUTES.IMPORT_EXPORT,
    icon: 'ArrowsUpDownIcon',
    visible: true,
  },
  {
    id: 'uploadCustomers',
    name: 'Upload Customers',
    href: ROUTES.UPLOAD_CUSTOMERS,
    icon: 'UserPlusIcon',
    visible: CSS_MODE === STANDALONE_S3,
  },
  {
    id: 'systemParams',
    name: 'System Parameters',
    href: ROUTES.SYSTEM_PARAMETERS,
    icon: 'Cog6ToothIcon',
    visible: true,
  },
  {
    id: 'users',
    name: 'Users',
    href: ROUTES.USER_MANAGEMENT,
    icon: 'IdentificationIcon',
    visible: true,
  },
]

const userNavigation: HeaderNavigation[] = [
  { name: 'Change Password', href: ROUTES.CHANGE_PSSW, visible: true },
  { name: '2 Factor Auth.', href: ROUTES.TWO_FACTOR, visible: false },
  { name: 'Logout', visible: true },
]

const Header: React.FC = () => {
  const location = useLocation()
  const size = useWindowWidth()
  const history = useHistory()

  const user = useUser()

  const context = useContext(AuthenticationContext)
  const logout = () =>
    authService.logout(context.logout).then(() => {
      history.push(ROUTES.LOGIN)
    })

  return (
    <div className="min-h-full">
      {user && size > MIN_WIDTH && (
        <Disclosure as="nav" className="bg-white border-b border-gray-200">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to={ROUTES.HOME}>
                    <img src="../logo.svg" alt="CSSNG" />
                  </Link>
                </div>
                {size > BURGER_MIN_WIDTH && (
                  <HeaderLinks
                    navigation={navigation}
                    location={location}
                    user={user}
                  />
                )}
              </div>

              {size > BURGER_MIN_WIDTH ? (
                <div className="sm:ml-6 sm:flex sm:items-center">
                  <HeaderMenu
                    user={user}
                    userNavigation={userNavigation}
                    history={history}
                    logout={logout}
                  />
                </div>
              ) : (
                <div className="sm:ml-6 sm:flex sm:items-center text-purple">
                  <BurgerMenu
                    user={user}
                    navigation={navigation}
                    userNavigation={userNavigation}
                    history={history}
                    logout={logout}
                  />
                </div>
              )}
            </div>
          </div>
        </Disclosure>
      )}
    </div>
  )
}
export default Header
