export const ROUTES = {
  LOGIN: '/login',
  VERIFY: '/verify',
  CHANGE_PSSW: '/change-password',
  FORGOT_PSSW: '/forgot-password',
  TWO_FACTOR: '/twofactor-authentication',
  DASHBOARD: '/dashboard',
  REPORTS: '/reporting',
  CUSTOMER_MANAGEMENT: '/customer-management',
  LAYOUT_DESIGNER: '/layout-designer',
  CONFIGURATION: '/configuration',
  SYSTEM_PARAMETERS: '/system-parameters',
  UPLOAD_CUSTOMERS: '/upload-customers',
  USER_MANAGEMENT: '/users',
  IMPORT_EXPORT: '/import-export',
  NO_PERMISSIONS: '/no-permissions',
  HOME: '/',
}

export const SERVICES = {
  CHANGE_PSSW: '/me/password/change',
  DEACTIVATE: '/me/deactivate',
  FOROT_PSSW: '/auth/forgot_password',

  LOGIN: '/auth/login',
  VERIFY_OTP: '/auth/otp',
  RESEND_OTP: '/auth/otp/resend',

  TWO_FACTOR: '/me/mfa/setup',
  REFRESH_BACKUP_CODES: '/me/mfa/reset/backup-codes',

  METRICS_TOTAL: '/metrics/customers/total',
  METRICS_PERIOD: '/metrics/customers/period',
  METRICS_CHARTS: '/charts/customer-journeys',

  GET_CUSTOMER: '/manage/customers',
  GET_CONFIGURATION: '/config',
  GET_TEMPLATES: '/notification-templates',

  EXPORT_ITEMS: '/export/resources',
  IMPORT_ITEMS: '/import/resources',
  UPLOAD_IMPORT_ITEMS: '/upload-urls/import-resources',

  CARDS: '/cards',
  FORMS: '/dataforms',
  OPTIONS: '/options',

  NEW_PSSW: '/me/password/new',
  REFRESH_TOKEN: '/me/refresh',
  REGISTER: '/register',
  REGISTER_CODE: '/register/verify',

  UI_CONFIG: '/uiconfig',
  USER: '/me',
  USER_OPTIONS: '/me/options',
  USER_OPTIONS_MOCK: '/new2.json',
  POST_USER_OPTIONS: '/me/actions',
  PAGINATED_HISTORY: '/me/actions/history',
  HISTORY: '/me/actions/history',
  LOGOUT: '/me/logout',

  UPLOAD_CUSTOMERS: '/upload-urls/standalone-zip',
  EXPORT_EVENTS: '/export/events',
  USERS: '/users',

  THEME: '/theme',
  FLOWS: '/flows',
  TRANSLATIONS: '/translations',
}

// TODO Implement TTL functionality
export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'cssng-token',
  NEXT_PAGE: 'cssng-next-page',
  CONFIGURATION: 'cssng-configuration',
  CONFIGURATION_TAB: 'cssng-configuration-tabs',
  COLOR_SESSION: 'cssng-color-session',
  TRANSLATIONS_SESSION: 'cssng-configuration-translations',
  LANGUAGE_SESSION: 'cssng-configuration-languages',
  SYSTEM_PARAMETERS: 'cssng-system-parameters',
  CUSTOMER_INVITATION_BUTTON: 'cssng-custom-invitation',
  LANG: 'cssng-lang',
  CONFIG: 'cssng-config',
  USER: 'cssng-user',
  USER_OPTIONS: 'cssng-user-options',
  HISTORY: 'cssng-history',
  AUTH: 'cssng-auth',
}

export const STATUS_CODES = {
  TIMEOUT: 'ECONNABORTED',
  NO_NETWORK: 'ERR_NETWORK',
  SUCCESS: 200,
  VALIDATION_ERROR: 400,
  UNAUTHORIZED: 401,
  FORBIDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
}

export const DEFAULT_LANGUAGE = 'en'
export const VERIFICATION_CODE_EXPIRATION = 180 //in seconds

// 'https://unogroz4ik.execute-api.eu-north-1.amazonaws.com' //dev env
// 'https://3w981ww49l.execute-api.eu-north-1.amazonaws.com' //qa env
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

export const CSS_MODE = process.env.REACT_APP_CSS_MODE
export const CSS_ENV = process.env.REACT_APP_CSS_ENV
export const CSS_STACK = process.env.REACT_APP_CSS_STACK

export const EFS = 'efs'
export const STANDALONE_SFTP = 'standalone_sftp'
export const STANDALONE_S3 = 'standalone_s3'

export const EXUS = 'exus'
export const PROD_ENV = 'prod'

export const DEFAULT_HEADERS = { 'Content-Type': 'application/json' }

export const MIN_WIDTH = 834
export const BURGER_MIN_WIDTH = 1075

export const COMMON_DATE_FORMAT = 'DD MMM. YYYY'
export const COMMON_DATETIME_FORMAT = 'DD MMM. YYYY hh:mm'

export const COLOR_SESSION_LENGTH = 16

export const AUTH_MODE = {
  PASSWORD: 'password',
  MFA: 'mfa',
  OTP: 'otp',
}
