import { Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'
import { HeaderNavigation } from './Header'
import { UserResponse } from '../../model/authentication'

interface Props {
  user?: UserResponse
  history: any
  navigation: HeaderNavigation[]
  userNavigation: HeaderNavigation[]
  logout: () => Promise<void>
}

const BurgerMenu: React.FC<Props> = ({
  user,
  history,
  navigation,
  userNavigation,
  logout,
}) => {
  return (
    <Menu as="div" className="relative" style={{ zIndex: 2 }}>
      {({ open }) => (
        <React.Fragment>
          <div>
            <Menu.Button className="max-w-xs bg-white-600 hover:bg-lightGrey rounded-md p-2 flex items-center text-sm focus:outline-none">
              {open ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              {navigation
                .filter(
                  (item) =>
                    item?.visible && user?.permissions?.includes(item?.id)
                )
                .map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          item.href ? history.push(item.href) : logout()
                        }
                        className={
                          'block px-4 py-2 m-0 text-sm text-gray-700 hover:no-underline w-full text-left' +
                          (active ? ' bg-gray-100' : '')
                        }
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              {userNavigation
                .filter((item) => item?.visible)
                .map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          item.href ? history.push(item.href) : logout()
                        }
                        className={
                          'block px-4 py-2 m-0 text-sm text-gray-700 hover:no-underline w-full text-left' +
                          (active ? ' bg-gray-100' : '')
                        }
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </Menu.Items>
          </Transition>
        </React.Fragment>
      )}
    </Menu>
  )
}

export default BurgerMenu
