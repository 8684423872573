import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { HeaderNavigation } from './Header'
import MenuIcon from './MenuIcon'
import { UserResponse } from '../../model/authentication'

interface Props {
  location: any
  navigation: HeaderNavigation[]
  user: UserResponse
}

const HeaderLinks: React.FC<Props> = ({ navigation, location, user }) => {
  return (
    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
      {navigation
        .filter(
          (item) => item?.visible && user?.permissions?.includes(item?.id)
        )
        .map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className={
              'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium' +
              (location.pathname.endsWith(item.href) ||
              (location.pathname.endsWith('/') &&
                item.href === ROUTES.DASHBOARD)
                ? ' border-purple text-purple'
                : ' border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')
            }
            aria-current={
              location.pathname.endsWith(item.href) ||
              (location.pathname.endsWith('/') &&
                item.href === ROUTES.DASHBOARD)
                ? 'page'
                : undefined
            }
          >
            <MenuIcon icon={item?.icon} className="h-5 mr-1" />
            <span>{item.name}</span>
          </Link>
        ))}
    </div>
  )
}

export default HeaderLinks
