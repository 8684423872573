import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { classNames } from '../helpers'

export const Toast = ({
  status,
  content,
  remove,
}: {
  status: string
  content: any
  remove: any
}) => {
  return (
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
      <div
        className={classNames(
          'max-w-lg w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden',
          status === 'success' ? 'bg-green-500' : 'bg-red-600'
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {status === 'success' ? (
                <CheckCircleIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              ) : (
                <XCircleIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="mt-1 text-sm text-white">{content}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="rounded-md inline-flex text-white focus:outline-none"
                onClick={remove}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Toast
