import { useState, useEffect } from 'react'
import { UIService } from '../services'
import axiosClient from './axiosClient'
import { DEFAULT_HEADERS, BASE_URL, STATUS_CODES, SERVICES } from '../constants'
import { authService } from '../services'
import { toast } from 'react-toastify'
import { Toast } from '../components/Toast'
import { t } from 'i18next'
import bubbleColors from '../components/Avatar/bubbleColors.json'
import seedrandom from 'seedrandom'
import { UserResponse } from '../model/authentication'
import { BOConfig } from '../model/bo-config'

export const getAuthHeader = (token: string) =>
  token ? { Authorization: `Bearer ${token}` } : {}

// TODO Needs refactor
export const authHeader = (refresh = false) => {
  const { token } = authService
  if (token && refresh && token.refreshToken) {
    return getAuthHeader(token.refreshToken)
  }
  if (token && !refresh && token.accessToken) {
    return getAuthHeader(token.accessToken)
  }
  return {}
}

export const avatarProfile = (profile: {
  firstname: string
  lastname: string
  username: string
}) => {
  return {
    firstname: profile.firstname,
    lastname: profile.lastname,
    username: profile.username,
  }
}

const httpClient = axiosClient(authService)

export const axiosGet = (uri: string, headers = {}, signal?: AbortSignal) => {
  return httpClient.get(BASE_URL + uri, {
    headers: { ...DEFAULT_HEADERS, ...headers },
    signal: signal,
  })
}

export const axiosDelete = (
  uri: string,
  headers = {},
  signal?: AbortSignal
) => {
  return httpClient.delete(BASE_URL + uri, {
    headers: { ...DEFAULT_HEADERS, ...headers },
    signal: signal,
  })
}

export const axiosPost = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return httpClient.post(BASE_URL + uri, data, {
    headers: { ...DEFAULT_HEADERS, ...headers },
    signal: signal,
  })
}

export const axiosPatch = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return httpClient.patch(BASE_URL + uri, data, {
    headers: { ...DEFAULT_HEADERS, ...headers },
    signal: signal,
  })
}

export const axiosPut = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return httpClient.put(BASE_URL + uri, data, {
    headers: { ...DEFAULT_HEADERS, ...headers },
    signal: signal,
  })
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthGet = (
  uri: string,
  headers = {},
  signal?: AbortSignal
) => {
  return axiosGet(uri, { ...headers, ...authHeader() }, signal)
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthDelete = (
  uri: string,
  headers = {},
  signal?: AbortSignal
) => {
  return axiosDelete(uri, { ...headers, ...authHeader() }, signal)
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthPost = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return axiosPost(uri, data, { ...headers, ...authHeader() }, signal)
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthPatch = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return axiosPatch(uri, data, { ...headers, ...authHeader() }, signal)
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthPut = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return axiosPut(uri, data, { ...headers, ...authHeader() }, signal)
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosRefreshAuthPost = (
  uri: string,
  data = {},
  headers = {},
  signal?: AbortSignal
) => {
  return axiosPost(uri, data, { ...headers, ...authHeader(true) }, signal)
}

// TODO Add TTL to localstorage
export const setToken = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const getToken = (key: string) => {
  return JSON.parse(localStorage.getItem(key))
}

export const removeToken = (key: string) => {
  localStorage.removeItem(key)
}

let index = 0

export const transform = (obj) => {
  for (let i in obj) {
    if (obj[i] && typeof obj[i] === 'object') {
      if (!Array.isArray(obj[i])) {
        obj[i]['uid'] = index++
      }
      transform(obj[i])
    }
  }
}

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

export const nestedUpdate = (obj, levels, value) => {
  const lastIndex = levels.length - 1

  levels.forEach((level, idx) => {
    if (!(level in obj)) {
      // The following check returns true in case of string, so its an object or false in case
      // of number so its an array
      // eslint-disable-next-line no-self-compare
      obj[level] = parseInt(level) !== parseInt(level) ? {} : []
    }
    if (lastIndex !== idx) {
      obj = obj[level]
    }
  })
  obj[levels[lastIndex]] = value
}

export const nestedLangUpdate = (obj, levels, lang) => {
  levels.forEach((level) => {
    if (!(level in obj)) {
      // The following check returns true in case of string, so its an object or false in case
      // of number so its an array
      obj[level] =
        // eslint-disable-next-line no-self-compare
        parseInt(level) !== parseInt(level)
          ? level === 'localizedText'
            ? [lang]
            : {}
          : []
    } else {
      if (level === 'localizedText') obj[level].push(lang)
    }
    obj = obj[level]
  })
}

export const getNestedValue = (obj, levels) => {
  levels.forEach((level) => {
    if (level in obj) {
      obj = obj[level]
    }
  })

  return obj
}

export const deleteNestedValue = (obj, levels) => {
  levels.forEach((level) => {
    obj = obj[level]

    if (typeof obj === 'undefined') {
      return
    }
  })
  delete obj[levels.pop()]
}

export const useWindowWidth = () => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return screenWidth
}

export const useConfig = () => {
  const { config } = UIService

  const [configuration, setConfiguration] = useState<BOConfig>(
    config || undefined
  )

  useEffect(() => {
    UIService.fetchConfig()
      .then((res) => setConfiguration(res))
      .catch((e) => console.error(e))
  }, [])

  return configuration
}

export const useUser = () => {
  const { user } = UIService

  const [activeUser, setActiveUser] = useState<UserResponse>(user || undefined)
  useEffect(() => {
    const updateUser = () => {
      const { user } = UIService
      setActiveUser(user || undefined)
    }
    window.addEventListener('updateUser', updateUser)
    updateUser()
    return () => window.removeEventListener('updateUser', updateUser)
  }, [])
  return activeUser
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

const needsSuffix = (name: string) => {
  const callsNeedSufix = ['changePassword']
  return callsNeedSufix.includes(name)
}

export const showError = (translationKey: string, err?: any) => {
  let transformedKey = 'errorMessages.' + translationKey
  if (err) {
    transformedKey +=
      '.' +
      err?.response?.status +
      (err?.response?.data?.details?.error && needsSuffix(translationKey)
        ? '_' + err?.response?.data?.details?.error
        : '')
  }
  if (!isGlobalError(err?.code, err?.response?.status)) {
    addToast('error', t(transformedKey, 'Something went wrong'))
  }
  toast.clearWaitingQueue()
}

export const showSuccess = (translationKey: string) => {
  addToast('success', t(translationKey, 'Success'))
}

const addToast = (type: string, msg: string) => {
  toast(({ closeToast }) => (
    <Toast status={type} content={msg} remove={closeToast} />
  ))
}

export const isGlobalError = (code: string, status: number) => {
  switch (code) {
    case STATUS_CODES.TIMEOUT:
    case STATUS_CODES.NO_NETWORK:
      return true
  }

  switch (status) {
    case 400:
    case 401:
    case 500:
      return true
    default:
      return false
  }
}

const notHandledAsGlobalErrorUrls = [
  {
    url: BASE_URL + SERVICES.IMPORT_ITEMS + '?objectKey=',
    msg: 'errorMessages.importExport.import.invalidDataError',
  },
]

export const notHandledAsGlobal = (error: any) => {
  for (let i = 0; i < notHandledAsGlobalErrorUrls.length; i++)
    if (
      error.response.config.url.startsWith(notHandledAsGlobalErrorUrls[i].url)
    )
      return true

  return false
}

export const getErrorMessageFromError = (error: any) => {
  for (let i = 0; i < notHandledAsGlobalErrorUrls.length; i++) {
    if (
      error.response.config.url.startsWith(notHandledAsGlobalErrorUrls[i].url)
    ) {
      return notHandledAsGlobalErrorUrls[i].msg
    }
  }

  return ''
}

export const extractRecipients = (recipients: string) => {
  return recipients
    .split(/[, ]+/)
    .map((email) => {
      return email.trim()
    })
    .filter((item) => item !== '')
}

export const isHexColor = (hex: string) => {
  const regex = /^#([0-9a-f]{3,4}){1,2}$/i
  return regex.test(hex)
}

export const toSixDigitHex = (color: string) => {
  color = color.slice(1)
  if (color.length === 3) {
    color = color
      .split('')
      .map((hex) => {
        return hex + hex
      })
      .join('')
  }

  return '#' + color
}

const rgba = (color: any) => {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

export const rgba2hex = (color: any) => {
  let a: any,
    rgb = rgba(color)
      .replace(/\s/g, '')
      .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = ((rgb && rgb[4]) || '').trim(),
    hex = rgb
      ? (Number(rgb[1]) | (1 << 8)).toString(16).slice(1) +
        (Number(rgb[2]) | (1 << 8)).toString(16).slice(1) +
        (Number(rgb[3]) | (1 << 8)).toString(16).slice(1)
      : rgba(color)

  if (alpha !== '') {
    a = alpha
  } else {
    a = 0o1
  }
  // multiply before convert to HEX
  a = ((a * 255) | (1 << 8)).toString(16).slice(1)
  hex = hex + a

  return '#' + hex
}

export const setDifferentKeys = (arr: any[]) => {
  let keys = []
  for (let i in arr) {
    const obj = arr[i]
    for (let key in obj) {
      if (key !== 'date' && !keys.includes(key)) {
        keys.push(key)
      }
    }
  }
}

export const flattenColors = (colors) => {
  const colorsArray = Object.entries(colors)
  const cols = colorsArray.map((item) => item[1])
  const cols1 = cols.map((item) => Object.entries(item))
  const cols2 = cols1.map((item) => item.map((nestedItem) => nestedItem[1]))
  return cols2.flat()
}

export const colorForString = (string: string) => {
  const colors = flattenColors(bubbleColors)
  let colorIndex = seedrandom.alea(string).int32() % colors.length
  colorIndex = colorIndex < 0 ? -colorIndex : colorIndex
  return colors[colorIndex]
}

export const isUrl = (str: string) => {
  let url: URL
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)
