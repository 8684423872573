import { createBrowserHistory } from 'history'
import React, { Suspense, useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, MainContainer } from './components/common'
import Footer from './components/Footer'
import Header from './components/Header/Header'
import Loader from './components/Loader'
import { CSS_MODE, ROUTES, STANDALONE_S3 } from './constants'
import Authentication from './helpers/Authentication'
import PrivateRoute from './PrivateRoute'
import ScreenSizeErrorRoute from './ScreenSizeErrorRoute'
import * as themeConfig from './theme'

const Login = React.lazy(() => import('./pages/Login'))
const TwoFactorAuthentication = React.lazy(
  () => import('./pages/TwoFactorAuthentication')
)
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'))

const Dashboard = React.lazy(() => import('./pages/Dashboard'))
const Reports = React.lazy(() => import('./pages/Reports'))
const CustomerManagement = React.lazy(
  () => import('./pages/CustomerManagement')
)

const SystemParameters = React.lazy(() => import('./pages/SystemParameters'))
const Configuration = React.lazy(() => import('./pages/Configuration'))
const UserManagement = React.lazy(() => import('./pages/UserManagement'))
const ImportExport = React.lazy(() => import('./pages/ImportExport'))
const UploadCustomers = React.lazy(() => import('./pages/UploadCustomers'))

const NotFound = React.lazy(() => import('./pages/NotFound'))
const NoPermissions = React.lazy(() => import('./pages/NoPermissions'))

const history = createBrowserHistory()
const theme = { ...themeConfig }

const App: React.FC = () => {
  useEffect(() => {
    const link = document.createElement('link')
    link.href = themeConfig.fonts.fontUrl
    link.rel = 'stylesheet'
    document.head.appendChild(link)
    return () => {
      document.head.removeChild(link)
    }
  }, [])

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Suspense fallback={<Loader />}>
          <Authentication>
            <Router history={history}>
              <Header />
              <MainContainer>
                <Switch>
                  <ScreenSizeErrorRoute path={ROUTES.LOGIN} component={Login} />
                  <ScreenSizeErrorRoute
                    path={ROUTES.TWO_FACTOR}
                    component={TwoFactorAuthentication}
                  />
                  <ScreenSizeErrorRoute
                    path={ROUTES.CHANGE_PSSW}
                    component={ChangePassword}
                  />
                  <PrivateRoute path={ROUTES.DASHBOARD} component={Dashboard} />
                  <PrivateRoute exact path={ROUTES.HOME} component={Reports} />
                  <PrivateRoute
                    exact
                    path={ROUTES.REPORTS}
                    component={Reports}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.CUSTOMER_MANAGEMENT}
                    component={CustomerManagement}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.SYSTEM_PARAMETERS}
                    component={SystemParameters}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.CONFIGURATION}
                    component={Configuration}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.USER_MANAGEMENT}
                    component={UserManagement}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.IMPORT_EXPORT}
                    component={ImportExport}
                  />
                  {CSS_MODE === STANDALONE_S3 && (
                    <PrivateRoute
                      exact
                      path={ROUTES.UPLOAD_CUSTOMERS}
                      component={UploadCustomers}
                    />
                  )}
                  <Route
                    exact
                    path={ROUTES.NO_PERMISSIONS}
                    component={NoPermissions}
                  />
                  <Route component={NotFound} />
                </Switch>
              </MainContainer>
              <Footer />
            </Router>
          </Authentication>
        </Suspense>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App
