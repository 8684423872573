import { Route, Redirect } from 'react-router-dom'
import { MIN_WIDTH, ROUTES } from './constants'
import { useUser, useWindowWidth } from './helpers'
import { authService } from './services'
import { ScreenSizeError } from './components/ScreenSizeError'
import LoadingUser from './components/LoadingUser'
import { navigation } from './components/Header/Header'

export default function PrivateRoute({ component: Component, ...rest }) {
  const size = useWindowWidth()
  const user = useUser()

  return (
    <Route
      {...rest}
      render={(props) => {
        const { token, next_page } = authService
        if (!token?.accessToken || next_page !== 'all') {
          return (
            <Redirect
              to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }}
            />
          )
        }

        if (user) {
          const target = navigation?.find(
            (nav) => nav?.href === props?.location?.pathname
          )

          // Redirect user to dashboard page in case of no access rights (not in the scope)
          if (
            !user?.permissions?.includes(target?.id) &&
            props?.location?.pathname !== ROUTES.DASHBOARD
          )
            return (
              <Redirect
                to={{
                  pathname: ROUTES.DASHBOARD,
                  state: { from: props.location },
                }}
              />
            )
        } else {
          return <LoadingUser user={user} />
        }

        if (size > MIN_WIDTH) {
          return <Component {...props} />
        } else {
          return <ScreenSizeError />
        }
      }}
    />
  )
}
