import axios from 'axios'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import { getErrorMessageFromError, notHandledAsGlobal } from '.'
import { Toast } from '../components/Toast'
import { STATUS_CODES, ROUTES } from '../constants'

const axiosClient = (authService) => {
  let client = axios.create()

  client.interceptors.request.use((req) => {
    const { width, height } = window.screen
    const { clientWidth, clientHeight } = document.documentElement

    const userMetaHeader = {
      'X-UA-Metadata': `sr=${width}x${height}&wr=${clientWidth}x${clientHeight}&bl=${navigator.language}`,
    }

    return {
      ...req,
      headers: { ...req.headers, ...userMetaHeader },
    }
  })

  client.interceptors.response.use(
    (res) => res,
    (err) => {
      const { response } = err
      if (response && response.status === STATUS_CODES.UNAUTHORIZED) {
        authService.logout()
        window.location.href = ROUTES.LOGIN
      } else if (
        err.code === STATUS_CODES.TIMEOUT ||
        err.code === STATUS_CODES.NO_NETWORK
      ) {
        toast(({ closeToast }) => (
          <Toast
            status="error"
            content={t('errorMessages.global.timeout')}
            remove={closeToast}
          />
        ))
      } else if (
        response &&
        (response.status === STATUS_CODES.SERVER_ERROR ||
          response.status === STATUS_CODES.VALIDATION_ERROR)
      ) {
        if (notHandledAsGlobal(err)) {
          toast(({ closeToast }) => (
            <Toast
              status="error"
              content={t(`${getErrorMessageFromError(err)}`)}
              remove={closeToast}
            />
          ))
        } else {
          const message = response?.data?.details?.type
            ? `_${String(response.data.details.type).toUpperCase()}`
            : ''
          toast(({ closeToast }) => (
            <Toast
              status="error"
              content={t(`errorMessages.global.${response.status + message}`)}
              remove={closeToast}
            />
          ))
        }
      }
      toast.clearWaitingQueue()
      return Promise.reject(err)
    }
  )
  return client
}
export default axiosClient
